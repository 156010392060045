import React from "react";
import Layout from "../components/layouts/main-layout";
import Container from "../components/container";
import ContactForm from "../components/contact-form";
import Button from "../components/button";

const Kontakt = () => {
  return (
    <Layout>
      <Container>
        <h1>Kontakt</h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <p>
              E-Mail:{" "}
              <a href="mailto:info@tidens-surfhuus.de" className="text-info">
                info@tidens-surfhuus.de
              </a>
            </p>
            <p>
              Telefon:{" "}
              <a href="tel:+4916099892384" className="text-info">
                +49 160 99892384
              </a>
            </p>
            <h2 className="mt-2">Öffnungszeiten</h2>
            Während der Saison (15. April - 15. Oktober) ist unsere Station am
            Weststrand (Übergang Hunpad) von{" "}
            <strong>10:00 Uhr bis 18:00 Uhr</strong> geöffnet. Sollten wir doch
            einmal geschlossen haben – oder auf dem Wasser sein – kannst Du uns
            jederzeit eine Nachricht hinterlassen.
            <h2 className="pt-5">Buchungsanfrage</h2>
            Du hast keinen passenden Termin für einen Kurs bei uns gefunden? Mit
            dem Formular „Buchungsanfrage“ kannst du uns eine Anfrage für deinen
            Wunschtermin zukommen lassen. Bitte nutze das Formular nur für
            Anfragen, die noch <strong>mindestens</strong> eine Woche in der Zukunft liegen. Wenn
            deine Anfrage relativ kurzfristig ist, melde dich bitte telefonisch
            bei uns.
            <br />
            <div className="mt-4">
              <Button
                external="true"
                to="https://docs.google.com/forms/d/e/1FAIpQLSedNmxlkNMQo-NWV1DMDF4Kn7f7eWHdyQMMD04xYPvszyJfWA/viewform"
                btntype="secondary"
                title="Buchungsanfrage"
              />
            </div>
          </div>

          <div>
            <ContactForm />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Kontakt;
