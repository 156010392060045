import React, { Component } from "react";
import Spinner from "./spinner";
import Arrow from "./icons/arrow";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const initialState = {
  formData: {
    name: "",
    email: "",
    message: "",
  },
  formsent: false,
  isLoading: false,
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ formsent: false, isLoading: true });

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Kontaktformular", ...this.state.formData }),
    })
      .then(() => {
        this.resetForm();
        this.setState({formsent: true});
        setTimeout(() => {
          this.setState({formsent: false});
        }, 3000);      
      })
      .catch((error) => alert(error));
  };

  handleChange = (e) =>
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });

  resetForm = () => this.setState(initialState);

  render() {
    const { name, email, message } = this.state.formData;
    return (
      <div>
        <form
          name="Kontaktformular"
          method="post"
          action="/kontakt"
          onSubmit={this.handleSubmit}
          data-netlify="true"
          netlify-honeypot="bot-field"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Kontakformular" />
          <p className="hidden">
            <label>
              {" "}
              Don’t fill this out: <input name="bot-field" />{" "}
            </label>
          </p>
          <h2 className="pb-5">Nachricht</h2>
          <p>
            <label>
              Dein Name:{" "}
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
                className="w-full rounded-md focus:border-info bg-secondary focus:bg-white"
                required
                minLength={2}
                maxLength={50}
              />
            </label>
          </p>
          <p>
            <label>
              Deine E-Mail Adresse:{" "}
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                className="w-full rounded-md focus:border-info bg-secondary focus:bg-white"
                required
                minLength={7}
                maxLength={65}
              />
            </label>
          </p>
          <p>
            <label>
              Nachricht:{" "}
              <textarea
                name="message"
                value={message}
                onChange={this.handleChange}
                className="w-full rounded-md focus:border-info bg-secondary focus:bg-white"
              />
            </label>
          </p>
          <p>
            <button
              type="submit"
              className={`inline-flex items-center rounded-full px-5 py-2 border-primary border-2 bg-primary text-white hover:font-bold ${
                this.state.isLoading ? "cursor-not-allowed" : ""
              }`}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading === true && <Spinner />}
              Nachricht senden <Arrow color="primary" className="ml-2" />
            </button>
          </p>
        </form>

        {this.state.formsent === true && (
          <div className="text-primary font-bold mt-4">
            Vielen Dank für Deine Nachricht. Wir werden uns zeitnah bei Dir
            zurückmelden.
          </div>
        )}
      </div>
    );
  }
}

export default ContactForm;
